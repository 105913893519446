import $ from 'jquery';
import gsap from 'gsap';

$(document).ready(function(){
  
    // wait until window is loaded - all images, styles-sheets, fonts, links, and other media assets
    $(window).on("load", function(){
      
        // OPTIONAL - waits til next tick render to run code (prevents running in the middle of render tick)
        window.requestAnimationFrame(function() {
          
            var timeline = new gsap.timeline();
            timeline.from(", .content", 1, {autoAlpha: 0},0)
          
        });
      
    });  
    
  });
              // Setup Sidebar Animations
var sidebarToggle = gsap.timeline ({paused: true, reversed: true});
sidebarToggle.to('.sidebar',{
width: "33vw",
autoAlpha: 1,
duration: 1,
ease:"power1.inOut",
},0);

var sidebarMobile = gsap.timeline ({paused: true, reversed: true});
sidebarMobile.from('.sidebar',{
autoAlpha: 0,
duration: .5,
ease:"power1.inOut",
},0);


// Sidebar Click Button
function fireSidebar() {
$('.info').click(function () {
  sidebarMobile.kill();
  sidebarToggle.reversed() ? sidebarToggle.timeScale(1.0).play() : sidebarToggle.timeScale(1.2).reverse();
});
}

function fireMobile() {
$('.info').click(function () {
  sidebarToggle.kill();
  sidebarMobile.reversed() ? sidebarMobile.timeScale(1.0).play() : sidebarMobile.timeScale(1.2).reverse();
});
}

//Check for Device Width
function isMobile() {
if ($(window).width() < 761) {
return fireMobile();

} else {
return fireSidebar();
}
};
isMobile();

//Reload Page onResize
$(window).resize(function(){location.reload();});